<adapt-dismissible-cta [flag]="PersonFlag.CoachSessionDismissed"
                       [ctaStyle]="2">
    <h2 adapt-cta-title>Want to talk to one of our experienced coaches?
    </h2>

    <button adapt-cta-action
            data-tour="personal-dashboard-tour-cta"
            class="btn btn-primary px-4"
            (click)="gotoCoachRequestPage()">
        Talk to a coach
        <i class="fal fa-fw fa-arrow-right ms-2"></i>
    </button>

    <div>
        Each organisation gets a FREE 30 minute session with one of our experienced coaches.
        <br>
        Get some answers to common problems, help with the platform and more.
    </div>
</adapt-dismissible-cta>
