import { Inject, Injectable } from "@angular/core";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { MeetingAgendaTemplate } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-template";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { FunctionUtilities } from "@common/lib/utilities/function-utilities";
import { TEAM_DASHBOARD_PAGE } from "@common/page-route-providers";
import { NavigationHierarchyService } from "@common/route/navigation-hierarchy.service";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { RouteService } from "@common/route/route.service";
import { ShellUiService } from "@common/shell/shell-ui.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { BehaviorSubject, EMPTY, of } from "rxjs";
import { filter, map, switchMap, take, tap } from "rxjs/operators";
import { KanbanAuthService } from "../kanban/kanban-auth.service";
import { teamKanbanPageRoute } from "../kanban/kanban-page/kanban-page.route";
import { MeetingTabId } from "../shell/meeting-tab-content/meeting-tab-id";
import { MenuTabId } from "../shell/menu-tab-content/menu-tab-id";
import { TeamTabId } from "../shell/team-tab-content/team-tab-id";
import { WorkflowService } from "../workflow/workflow.service";
import { WorkflowMeetingService } from "../workflow/workflow-meeting.service";
import { WorkflowRunDialogComponent } from "../workflow/workflow-run-dialog/workflow-run-dialog.component";
import { EditAgendaItemDialogComponent } from "./edit-agenda-item-dialog/edit-agenda-item-dialog.component";
import { EditAgendaTemplateDialogComponent } from "./edit-agenda-template-dialog/edit-agenda-template-dialog.component";
import { EditMeetingAgendaDialogComponent } from "./edit-meeting-agenda-dialog/edit-meeting-agenda-dialog.component";
import { ImportFromAgendaTemplateDialogComponent } from "./import-from-agenda-template-dialog/import-from-agenda-template-dialog.component";
import { ImportFromOtherMeetingAgendaDialogComponent } from "./import-from-other-meeting-agenda-dialog/import-from-other-meeting-agenda-dialog.component";
import { MeetingPreWorkDialogComponent } from "./meeting-pre-work-dialog/meeting-pre-work-dialog.component";
import { MeetingsService } from "./meetings.service";
import { editMeetingWorkflow, IScheduleMeetingWorkflowRunData, scheduleMeetingWorkflow } from "./schedule-meeting-workflow/schedule-meeting-workflow";

@Injectable({
    providedIn: "root",
})
export class MeetingsUiService {
    // default to using active meeting page on hq
    private useActiveMeetingPageSubject = new BehaviorSubject<boolean>(AdaptClientConfiguration.AdaptProject === AdaptProject.Alto);

    public constructor(
        private commonDialogService: AdaptCommonDialogService,
        private meetingsService: MeetingsService,
        private workflowService: WorkflowService,
        private workflowMeetingService: WorkflowMeetingService,
        private orgService: OrganisationService,
        private navHierarchyService: NavigationHierarchyService,
        private shellUiService: ShellUiService,
        private routeService: RouteService,
        private kanbanAuthService: KanbanAuthService,
        @Inject(TEAM_DASHBOARD_PAGE) private teamDashboardPageRoute: IAdaptRoute<{ teamId: number }>,
    ) {
    }

    public startMeeting(meeting: Meeting, callbackAfterSave?: () => any) {
        return this.meetingsService.saveEntities(meeting.extensions.startMeeting()).pipe(
            tap(() => {
                if (FunctionUtilities.isFunction(callbackAfterSave)) {
                    callbackAfterSave();
                }
            }),
            switchMap(() => this.isUsingActiveMeetingPage),
            tap((isUsingActiveMeetingPage) => {
                if (isUsingActiveMeetingPage) {
                    this.meetingsService.gotoActiveMeetingPage(meeting, undefined, true);
                } else if (this.kanbanAuthService.canEditPrivateBoardsForTeam(meeting.team!)) {
                    teamKanbanPageRoute.gotoRoute({ teamId: meeting.teamId });
                } else {
                    this.teamDashboardPageRoute.gotoRoute({ teamId: meeting.teamId });
                }
            }),
        );
    }

    public moveMeetingToActivePage(meeting: Meeting, returnWorkflowPath?: string, showDescription = false) {
        this.useActiveMeetingPage(true);
        if (meeting) {
            return this.meetingsService.gotoActiveMeetingPage(meeting, returnWorkflowPath, showDescription);
        }

        return EMPTY;
    }

    public moveMeetingToSidebar(meeting?: Meeting) {
        this.useActiveMeetingPage(false);
        setTimeout(() => {
            if (meeting) {
                this.shellUiService.focusTab(MeetingTabId);
            }
        });
    }

    public useActiveMeetingPage(useActiveMeetingPage: boolean) {
        this.useActiveMeetingPageSubject.next(useActiveMeetingPage);
        if (useActiveMeetingPage) {
            const tab = AdaptClientConfiguration.AdaptProject === AdaptProject.Alto ? MenuTabId : TeamTabId;
            this.shellUiService.focusTab(tab);
        }
    }

    public get isUsingActiveMeetingPage$() {
        return this.useActiveMeetingPageSubject.asObservable();
    }

    public get isUsingActiveMeetingPage() {
        return this.isUsingActiveMeetingPage$.pipe(take(1));
    }

    public editMeetingAgendaItem(agendaItem: MeetingAgendaItem, saveOnClose: boolean) {
        return this.commonDialogService.open(EditAgendaItemDialogComponent, { agendaItem, saveOnClose });
    }

    public editAgendaTemplate(agendaTemplate: MeetingAgendaTemplate) {
        return this.commonDialogService.open(EditAgendaTemplateDialogComponent, agendaTemplate);
    }

    public importFromAgendaTemplate(saveOnClose: boolean) {
        return this.commonDialogService.open(ImportFromAgendaTemplateDialogComponent, saveOnClose);
    }

    public importFromOtherMeetingAgenda(saveOnClose: boolean, currentMeeting?: Meeting) {
        return this.commonDialogService.open(ImportFromOtherMeetingAgendaDialogComponent, { currentMeeting, saveOnClose });
    }

    public showMeetingPreWork(meeting: Meeting) {
        return this.commonDialogService.open(MeetingPreWorkDialogComponent, meeting);
    }

    public createMeeting(teamId: number) {
        return this.meetingsService.createMeeting(teamId).pipe(
            switchMap((meeting) => this.editMeeting(meeting)),
        );
    }

    public editMeeting(meeting: Meeting, titleOverride?: string) {
        return this.commonDialogService.open(WorkflowRunDialogComponent, {
            workflow: meeting.entityAspect.entityState.isAdded()
                ? scheduleMeetingWorkflow
                : editMeetingWorkflow,
            runData: { meeting, createTeamsMeeting: false } as IScheduleMeetingWorkflowRunData,
            titleOverride,
        }).pipe(
            map(() => meeting),
        );
    }

    public editMeetingAgenda(meeting: Meeting) {
        return this.commonDialogService.open(EditMeetingAgendaDialogComponent, meeting);
    }

    public promptForEndedMeetingAgendaEdit() {
        return this.commonDialogService.openConfirmationDialog({
            title: "Edit ended meeting agenda",
            message: `<p>Are you sure you want to edit the agenda of this <b>ENDED</b> meeting?</p>
                        <p><small>Meetings were recorded with the participation of other attendees.
                        Please ensure all involved are aware of the edited contents.</small></p>`,
            confirmButtonText: "Continue with Edit",
            cancelButtonText: "Cancel",
        });
    }

    @Autobind
    public endMeeting(meeting: Meeting, returnPath?: string) {
        const confirmationDialogData: IConfirmationDialogData = {
            title: "Ending meeting",
            message: "Are you sure you want to end the meeting?",
            confirmButtonText: "End",
            cancelButtonText: "Cancel",
        };

        return this.commonDialogService.openConfirmationDialogWithBoolean(confirmationDialogData).pipe(
            filter((stopMeeting) => !!stopMeeting),
            switchMap(() => this.meetingsService.saveEntities(meeting.extensions.endMeeting())),
            switchMap(() => returnPath
                ? of(returnPath)
                : this.workflowMeetingService.getMeetingWorkflowConnection(meeting).pipe(
                    switchMap((workflowConnection) => workflowConnection
                        ? this.workflowService.getPersonalPageWithContinueWorkflow(workflowConnection.workflowId).pipe(
                            map((route) => route.url))
                        : of(returnPath)),
                )),
            switchMap((path) => path
                ? this.routeService.navigateByUrl(path)
                : this.meetingsService.gotoMeetingPage(meeting)),
            tap(() => {
                this.useActiveMeetingPage(AdaptClientConfiguration.AdaptProject === AdaptProject.Alto);
                this.navHierarchyService.updateActiveNodeFromUrl();
                this.orgService.notifyOrganisationChangedEvent(); // this will trigger tab switching when node already active
            }),
        );
    }

    @Autobind
    public rescheduleMeeting(meeting: Meeting) {
        return this.editMeeting(this.meetingsService.rescheduleMeeting(meeting), "Reschedule meeting").pipe(
            switchMap(() => this.meetingsService.gotoMeetingPage(meeting)),
            tap(() => {
                this.useActiveMeetingPage(AdaptClientConfiguration.AdaptProject === AdaptProject.Alto);
                this.navHierarchyService.updateActiveNodeFromUrl();
                this.orgService.notifyOrganisationChangedEvent(); // this will trigger tab switching when node already active
            }),
        );
    }
}
