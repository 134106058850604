import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from "@angular/core";
import { IMeetingCustomData, IMeetingLocation, Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAttendee } from "@common/ADAPT.Common.Model/organisation/meeting-attendee";
import { IMeetingDuration } from "@common/ADAPT.Common.Model/organisation/meeting-extensions";
import { CalendarIntegrationProvider } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { IFocusable } from "@common/ux/adapt-common-dialog/focusable";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Event, ScheduleInformation } from "@microsoft/microsoft-graph-types-beta";
import { DxTextBoxComponent } from "devextreme-angular";
import { filter, lastValueFrom, of, skip, Subject, switchMap } from "rxjs";
import { catchError, debounceTime, tap } from "rxjs/operators";
import { ICalendarProvider, IMeetingOrganiserInfo } from "../../calendar/calendar.interface";
import { CalendarIntegrationUtilities } from "../../calendar/calendar-integration-utilities";
import { MicrosoftCalendarService } from "../../calendar/microsoft-calendar.service";
import { DirectoryAuthService } from "../../directory-shared/directory-auth.service";
import { DirectorySharedService } from "../../directory-shared/directory-shared.service";
import { OAuthService } from "../../oauth/oauth.service";
import { MeetingsService } from "../meetings.service";
import { SelectMeetingLocationComponent } from "../select-meeting-location/select-meeting-location.component";

@Component({
    selector: "adapt-schedule-meeting",
    templateUrl: "./schedule-meeting.component.html",
    styleUrls: ["./schedule-meeting.component.scss"],
})
export class ScheduleMeetingComponent extends BaseComponent implements OnInit, IFocusable {
    public readonly PrivateEmailLearnMoreUrl = ImplementationKitService.GetArticleLink(ImplementationKitArticle.CalendarIntegrationPrivateEmail);

    @Input() public meeting?: Meeting;
    @Input() public editableDetails = true;
    @Input() public disabled = false;
    @Output() public entitiesChange = new EventEmitter<IBreezeEntity[]>();
    @Output() public meetingLocationChange = new EventEmitter<IMeetingLocation | undefined>();
    @Output() public hasConflictsChange = new EventEmitter<boolean>();
    @Output() public organiserInfoChange = new EventEmitter<IMeetingOrganiserInfo>();

    @Input() public sendInvitations = true;
    @Output() public sendInvitationsChange = new EventEmitter<boolean>();

    @Input() public createTeamsMeeting = false;
    @Output() public createTeamsMeetingChange = new EventEmitter<boolean>();

    @ViewChild("focus") public elementToFocus?: DxTextBoxComponent;
    @ViewChild(SelectMeetingLocationComponent) public selectMeetingLocationComponent!: SelectMeetingLocationComponent;

    public attendeeFilter?: (person: Person) => boolean;

    public attendeePeople: Person[] = [];
    public deletedAttendees: MeetingAttendee[] = [];

    public meetingLocation?: IMeetingLocation;

    public showInvitationPrompt = false;
    public showTeamsPrompt = false;
    public checkingProviderStatus = true;
    private checkingProviderStatusUpdater = this.createThrottledUpdater<boolean>((loading) => this.checkingProviderStatus = loading, 250);

    public checkAvailability = new Subject<void>();
    public locationAvailable?: boolean;
    public peopleAvailable: string[] = [];
    public peopleUnavailable: string[] = [];
    public peopleNoEmail: string[] = [];
    public loadingAvailability = false;
    private loadingAvailabilityUpdater = this.createThrottledUpdater<boolean>((loading) => this.loadingAvailability = loading);

    private calendarIntegrationUtilities: CalendarIntegrationUtilities;
    private existingProviderMeeting?: Event;

    public constructor(
        injector: Injector,
        private microsoftCalendarService: MicrosoftCalendarService,
        private meetingsService: MeetingsService,
        private commonDataService: CommonDataService,
        private directorySharedService: DirectorySharedService,
        private directoryAuthService: DirectoryAuthService,
        private oauthService: OAuthService,
    ) {
        super();
        this.calendarIntegrationUtilities = new CalendarIntegrationUtilities(injector);

        this.oauthService.authenticationStatusWithProvider$.pipe(
            skip(1), // skip first since we get this synchronously in ngOnInit
            this.takeUntilDestroyed(),
        ).subscribe(([authed, provider]) => {
            this.onIntegrationAuthChange(authed, provider);
        });

        this.checkAvailability.pipe(
            // we only support this for microsoft so far...
            switchMap(() => this.oauthService.isAuthedWithProvider(CalendarIntegrationProvider.Microsoft)),
            filter((isAuthed) => isAuthed),
            tap(() => this.loadingAvailabilityUpdater.next(true)),
            debounceTime(1000),
            switchMap(() => this.updateAvailability()),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public getElementToFocus() {
        return this.elementToFocus;
    }

    public get isOrganiser() {
        return this.meetingOrganiser?.isOrganiser ?? true;
    }

    public get organiserName() {
        return this.meetingOrganiser?.name ?? "the organiser";
    }

    private get meetingOrganiser() {
        if (this.existingProviderMeeting && this.meeting) {
            const customData = this.meeting.extensions.getCustomData<IMeetingCustomData>();
            return {
                isOrganiser: customData.microsoftUserId === this.oauthService.user?.userId,
                name: this.existingProviderMeeting.organizer?.emailAddress?.name ?? "the organiser",
            } as IMeetingOrganiserInfo;
        }

        return undefined;
    }

    public async ngOnInit() {
        if (this.meeting) {
            this.meeting.extensions.updateSelectedDuration();

            // create supp data for meeting if doesn't exist already
            if (this.meeting && !this.meeting.supplementaryData) {
                const suppData = await lastValueFrom(this.meetingsService.createMeetingSupplementaryData(this.meeting));
                this.meeting!.supplementaryData = suppData;
            }

            // only create default attendees if there are not already attendees
            if (this.meeting.entityAspect.entityState.isAdded() && this.meeting.meetingAttendees.length === 0) {
                await lastValueFrom(this.meetingsService.createDefaultMeetingAttendees(this.meeting));
            }
            this.attendeePeople = this.meeting.meetingAttendees.map((attendee) => attendee.attendee);

            const customData = this.meeting.extensions.getCustomData<IMeetingCustomData>();

            // restore the meeting location from the saved customData
            if (this.meeting.location || customData.microsoftLocation) {
                this.meetingLocation = {
                    name: this.meeting.location,
                    emailAddress: customData.microsoftLocation,
                };

                // make sure we fire this so that it's included in runData for calendar integration
                this.onMeetingLocationChange(this.meetingLocation);
            }

            const [authed, provider] = await lastValueFrom(this.oauthService.authenticationStatusWithProvider);
            await this.onIntegrationAuthChange(authed, provider);

            this.emitEntityChange();
        }
    }

    public onSendInvitationsChange(sendInvitations: boolean, updateCustomData = true) {
        this.sendInvitations = sendInvitations;
        this.sendInvitationsChange.emit(sendInvitations);

        // save invitation sent status into custom data so we can restore that state while editing
        if (updateCustomData) {
            const customData = this.meeting!.extensions.getCustomData<IMeetingCustomData>();
            customData.invitationsSent = sendInvitations;
            this.meeting!.extensions.updateCustomData(customData);
            this.emitEntityChange();
        }

        if (!this.sendInvitations) {
            this.onCreateTeamsMeetingChange(false);
        }
    }

    public onCreateTeamsMeetingChange(createTeamsMeeting: boolean) {
        const originalCreateTeamsMeeting = this.createTeamsMeeting;
        this.createTeamsMeeting = createTeamsMeeting;
        this.createTeamsMeetingChange.emit(createTeamsMeeting);

        // update a meeting field to cause changes, so that entityTypeChanged will fire on save
        if (originalCreateTeamsMeeting !== this.createTeamsMeeting) {
            this.meeting!.lastUpdatedDateTime = new Date();
        }
    }

    @Autobind
    public meetingAttendeeFilterFunction(person: Person) {
        if (!this.meeting) {
            return false;
        }

        return !this.meeting.meetingAttendees.find((meetingAttendee) => meetingAttendee.attendeeId === person.personId)
            && this.meetingsService.personCanParticipateInMeeting(person, this.meeting)
            && (!this.meeting.team?.isPrivate || this.meetingsService.personCanEditMeeting(person, this.meeting));
    }

    public async onMeetingAttendeesChange(people: Person[]) {
        if (this.meeting) {
            const removedPeople = this.attendeePeople.filter((p) => !people.includes(p));
            for (const person of removedPeople) {
                const existingAttendee = this.meeting.meetingAttendees.find((a) => a.attendee === person);
                if (existingAttendee) {
                    // don't need to record the deleted attendee if it was added without saving
                    if (!existingAttendee.entityAspect.entityState.isAdded()) {
                        this.deletedAttendees.push(existingAttendee);
                    }
                    await lastValueFrom(this.commonDataService.remove(existingAttendee));
                    ArrayUtilities.removeElementFromArray(person, this.attendeePeople);
                }
            }

            const addedPeople = people.filter((p) => !this.attendeePeople.includes(p));
            for (const person of addedPeople) {
                // this attendee was already deleted, just revert those changes
                const deletedAttendee = this.deletedAttendees.find((attendee) => attendee.attendeeId === person.personId);
                if (deletedAttendee) {
                    deletedAttendee.entityAspect.rejectChanges();
                    ArrayUtilities.removeElementFromArray(deletedAttendee, this.deletedAttendees);
                } else {
                    // person has not been an attendee, add them
                    await lastValueFrom(this.meetingsService.createMeetingAttendee(this.meeting!.meetingId, person.personId));
                }

                ArrayUtilities.addElementIfNotAlreadyExists(this.attendeePeople, person);
            }

            // only emit the entity change if anything actually changed
            if ((removedPeople.length + addedPeople.length) > 0) {
                this.emitEntityChange();
            }

            if (people.length) {
                // prime all contacts for the attendees so we can get their email
                // filter by profiles you can read for.
                await this.directorySharedService.promiseToGetContactDetailsByPersonIds(people
                    .filter((person) => this.directoryAuthService.currentPersonCanReadPersonsProfile(person))
                    .map((person) => person.personId));
            }

            this.checkAvailability.next();
        }
    }

    public onMeetingNameChange(name: string) {
        this.meeting!.name = name;
        this.emitEntityChange();
    }

    public onMeetingLocationChange(value?: IMeetingLocation) {
        const previousLocation = this.meetingLocation;

        this.meetingLocation = value;
        this.meetingLocationChange.emit(value);

        this.meeting!.location = this.meetingLocation?.name;
        this.emitEntityChange();

        if (!this.meetingLocation) {
            // location unset, therefore hide location available message
            this.locationAvailable = undefined;
            return;
        }

        this.locationAvailable = this.selectMeetingLocationComponent?.isLocationAvailable(this.meetingLocation);

        // meetingLocation has an email (i.e. does have availability), and we don't have the availability already
        // therefore check availability
        // also force a check if they select the same location twice (say for example they deleted a meeting or a schedule changed)
        const locationHasChanged = previousLocation !== this.meetingLocation;
        if (this.meetingLocation.emailAddress && (this.locationAvailable === undefined || !locationHasChanged)) {
            this.checkAvailability.next();
        }
    }

    public onMeetingStartTimeChange(date: Date) {
        this.meeting!.extensions.setMeetingDate(date);
        this.emitEntityChange();
        this.checkAvailability.next();
    }

    public onMeetingDurationChange(endDuration: IMeetingDuration) {
        this.meeting!.extensions.setMeetingEndDuration(endDuration);
        this.emitEntityChange();
        this.checkAvailability.next();
    }

    private emitEntityChange() {
        this.entitiesChange.emit([
            this.meeting!,
            this.meeting!.supplementaryData!,
            ...this.meeting!.meetingAttendees,
            ...this.deletedAttendees,
        ]);
    }

    private async onIntegrationAuthChange(authed: boolean, provider?: ICalendarProvider) {
        const isNewMeeting = this.meeting!.entityAspect.entityState.isAdded();
        this.checkingProviderStatusUpdater.next(!isNewMeeting);

        const isMicrosoft = authed && !!provider && provider?.id === CalendarIntegrationProvider.Microsoft;
        this.showTeamsPrompt = isMicrosoft;
        this.showInvitationPrompt = authed && !!provider
            && [CalendarIntegrationProvider.Local, CalendarIntegrationProvider.Microsoft].includes(provider.id);

        // check if there is a teams meeting already for this meeting
        if (!this.existingProviderMeeting && !isNewMeeting) {
            this.existingProviderMeeting = await lastValueFrom(this.calendarIntegrationUtilities.getProviderMeeting(this.meeting!));
            const shouldCreateTeamsMeeting = this.createTeamsMeeting || (this.existingProviderMeeting?.isOnlineMeeting ?? false);
            this.onCreateTeamsMeetingChange(shouldCreateTeamsMeeting);
        }

        const organiser = this.meetingOrganiser;
        this.organiserInfoChange.emit(organiser);

        // don't want to sent invites for ended meetings or if we explicitly didn't send invitations
        // or if the user isn't the original organiser
        const customData = this.meeting!.extensions.getCustomData<IMeetingCustomData>();
        const sendInvitations = !this.meeting!.extensions.isEnded
            && (customData.invitationsSent ?? true)
            && (organiser?.isOrganiser ?? true);
        this.onSendInvitationsChange(sendInvitations, false);

        if (!authed) {
            // reset all availability when logging out
            this.locationAvailable = undefined;
            this.peopleAvailable = [];
            this.peopleUnavailable = [];
            this.peopleNoEmail = [];
        }

        if (!!authed && !!provider) {
            this.checkAvailability.next();
        }

        this.checkingProviderStatusUpdater.next(false);
    }

    private updateAvailability() {
        return this.selectMeetingLocationComponent.getMeetingLocations().pipe(
            switchMap((locations) => {
                const meetingLocations = this.meetingLocation?.emailAddress ? locations.concat(this.meetingLocation) : locations;
                return this.microsoftCalendarService.getCalendarAvailability(this.meeting!, meetingLocations);
            }),
            catchError(() => of([] as ScheduleInformation[])),
            tap((availability) => {
                this.loadingAvailabilityUpdater.next(false);

                // passes availability to select-meeting-location, so we don't have to query it twice
                this.selectMeetingLocationComponent.setLocationAvailability(availability, this.meeting!);

                // select-meeting-location already knows if the room is available or not, so pull that info from there
                this.locationAvailable = this.meetingLocation
                    ? this.selectMeetingLocationComponent.isLocationAvailable(this.meetingLocation)
                    : undefined;

                this.peopleAvailable = [];
                this.peopleUnavailable = [];
                this.peopleNoEmail = this.attendeePeople
                    .filter((p) => !p.getLoginEmail()?.value)
                    .map((p) => p.firstName);
                const personFromEmail: Record<string, Person> = Object.fromEntries(this.attendeePeople.map((p) => [p.getLoginEmail()?.value, p]));
                for (const schedule of availability) {
                    const person = schedule.scheduleId
                        ? personFromEmail[schedule.scheduleId]
                        : undefined;
                    if (person) {
                        const available = this.microsoftCalendarService.isScheduleAvailable(schedule, this.meeting!);
                        (available ? this.peopleAvailable : this.peopleUnavailable).push(person.firstName);
                    }
                }

                this.hasConflictsChange.emit(this.locationAvailable === false || this.peopleUnavailable.length > 0);
            }),
        );
    }
}
